export enum Auth {
  SIGN_IN = 'Auth_SignIn',
  REGISTER = 'Auth_Register',
  CHOOSE_PASSWORD = 'Auth_ChoosePassword',
  FORGOT_PASSWORD = 'Auth_ForgotPassword',
  MY_ACCOUNT = 'My_Account',
}

export enum Dashboard {
  HOME = 'Dashboard_Home',
}

export enum Customers {
  INDEX = 'Customers_Index',
  DETAILS = 'Customers_Details'
}

export enum Companies {
  INDEX = 'Companies_Index',
  NEW = 'Companies_New',
  MY_COMPANY = 'Companies_MyCompany',
}

export enum Installations {
  INDEX = 'Installations_Index',
  NOT_TESTED = 'Installations_Not_Tested',
  FAILED = 'Installations_Failed',
  NEW = 'Installations_New',
  DETAILS = 'Installations_Details'
}

export enum Devices {
  DETAILS = 'Device_Details',
  LOGS = 'Device_Logs'
}

export enum Users {
  INDEX = 'Users_Index',
  EDIT = 'Users_Edit',
}

export enum Common {
  NOT_FOUND = 'NotFound',
}

export enum Setup {
  REGISTER_CUSTOMER = 'NewBliqvanger_RegisterCustomer',
  REGISTER_BLIQVANGER= 'NewBliqvanger_RegisterBliqvanger',
  REGISTER_HOUSE_CONNECTION = 'NewBliqvanger_RegisterHouse',
  DETAILS_OVERVIEW = 'NewBliqvanger_Overview',
}

export enum App {
  INSTALLER = 'INSTALLER',
}

export enum Employees {
  INDEX = 'Employees_Index',
  NEW = 'Employees_New',
  UPDATE = 'Employees_Update',
}

export enum Organizations {
  INDEX = 'Organizations_Index',
  NEW = 'Organizations_New',
}
