import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import auth from '@/store/auth';
import notifications from '@/store/notifications';
import i18n from '@/helpers/i18n';
import * as routeNames from '@/router/names';

Vue.use(VueRouter);

/* tslint:disable-next-line */
function isAuthorizedToAdminPages(to: any, from: any, next: any) {
  if (auth.hasAdminRole) {
    next();
  } else {
    next({ name: routeNames.Dashboard.HOME });
  }
}

function isAuthorizedToInstallerPages(to: any, from: any, next: any) {
  if (auth.hasInstallerRole) {
    next();
  } else {
    next({ name: routeNames.Dashboard.HOME });
  }
}

const unguardedRoutes: string[] = [
  routeNames.Common.NOT_FOUND,
  routeNames.Auth.SIGN_IN,
  routeNames.Auth.REGISTER,
  routeNames.Auth.CHOOSE_PASSWORD,
  routeNames.Auth.FORGOT_PASSWORD,
];

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth-layout" */ '../views/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'signin',
        name: routeNames.Auth.SIGN_IN,
        component: () => import(/* webpackChunkName: "signin" */ '../views/SignIn.vue'),
      },
      {
        path: 'register',
        name: routeNames.Auth.REGISTER,
        component: () => import(/* webpackChunkName: "register_index" */ '../views/register/Index.vue'),
      },
      {
        path: 'choose-password',
        name: routeNames.Auth.CHOOSE_PASSWORD,
        component: () => import(/* webpackChunkName: "choosepassword" */ '../views/ChoosePassword.vue'),
      },
      {
        path: 'forgot-password',
        name: routeNames.Auth.FORGOT_PASSWORD,
        component: () => import(/* webpackChunkName: "forgotpassword_index" */ '../views/forgot-password/Index.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard-layout" */ '../views/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: routeNames.Dashboard.HOME,
        component: () => import(/* webpackChunkName: "home" */ '../views/dashboard/Dashboard.vue'),
      },
      {
        path: 'installations',
        name: routeNames.Installations.INDEX,
        component: () => import(/* webpackChunkName: "installations" */ '../views/installations/Index.vue'),
      },
      {
        path: 'installations-failed',
        name: routeNames.Installations.FAILED,
        props: true,
        component: () => import(/* webpackChunkName: "installations-failed" */ '../views/installations/Failed.vue'),
      },
      {
        path: 'installations-not-tested',
        name: routeNames.Installations.NOT_TESTED,
        props: true,
        component: () => import(/* webpackChunkName: "installations-not-tested" */ '../views/installations/NotTested.vue'),
      },
      {
        path: 'installations/:igenId',
        name: routeNames.Installations.DETAILS,
        props: true,
        component: () => import(/* webpackChunkName: "installations_details" */ '../views/installations/InstallationDetails.vue'),
      },
      {
        path: 'installations/:igenId/:deviceId',
        name: routeNames.Devices.DETAILS,
        component: () => import(/* webpackChunkName: "device_details" */ '../views/device/DeviceDetails.vue'),
      },
      {
        path: 'installations/device/:deviceId/logs',
        name: routeNames.Devices.LOGS,
        component: () => import(/* webpackChunkName: "device_logs" */ '../views/device/DeviceLogs.vue'),
        beforeEnter: (to, from, next) => {
          if (auth.hasAdminRole) {
            next();
          } else {
            next({ name: routeNames.Common.NOT_FOUND });
          }
        },
      },
      {
        path: 'users',
        name: routeNames.Users.INDEX,
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
      },
      {
        path: 'companies',
        name: routeNames.Companies.INDEX,
        beforeEnter: isAuthorizedToAdminPages,
        component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
      },
      {
        path: '/companies/new',
        name: routeNames.Companies.NEW,
        beforeEnter: isAuthorizedToAdminPages,
        component: () => import(/* webpackChunkName: "companies_edit" */ '../views/companies/Edit.vue'),
      },
      {
        path: 'customers',
        name: routeNames.Customers.INDEX,
        component: () => import(/* webpackChunkName: "customers" */ '../views/customers/Customers.vue'),
      },
      {
        path: 'customers/:customerId',
        name: routeNames.Customers.DETAILS,
        props: true,
        component: () => import(/* webpackChunkName: "customers_details" */ '../views/customers/CustomerDetails.vue'),
      },
      {
        path: '/setup',
        name: 'NewBV',
        redirect: { name: routeNames.Setup.REGISTER_CUSTOMER },
        component: () => import(/* webpackChunkName: "newbliqvanger" */ '../views/setup/SetupLayout.vue'),
        children: [
          {
            path: 'register-customer',
            name: routeNames.Setup.REGISTER_CUSTOMER,
            props: true,
            component: () => import(/* webpackChunkName: "newbliqvanger-registercustomer" */ '../views/setup/RegisterCustomer.vue'),
          },
          {
            path: 'register-bliqvanger',
            name: routeNames.Setup.REGISTER_BLIQVANGER,
            props: true,
            component: () => import(/* webpackChunkName: "newbliqvanger-registerbliqvanger" */ '../views/setup/RegisterBliqvanger.vue'),
          },
          {
            path: 'register-connection',
            name: routeNames.Setup.REGISTER_HOUSE_CONNECTION,
            props: true,
            component: () => import(/* webpackChunkName: "newbliqvanger-registerconnection" */ '../views/setup/RegisterHouseConnection.vue'),
          },
          {
            path: 'overview',
            name: routeNames.Setup.DETAILS_OVERVIEW,
            component: () => import(/* webpackChunkName: "newbliqvanger-overview" */ '../views/setup/Overview.vue'),
          },
        ],
      },
      {
        path: '/users/edit',
        name: routeNames.Users.EDIT,
        beforeEnter: isAuthorizedToAdminPages,
        component: () => import(/* webpackChunkName: "users_edit" */ '../views/users/Edit.vue'),
      },
      {
        path: '/my-account',
        name: routeNames.Auth.MY_ACCOUNT,
        component: () => import(/* webpackChunkName: "users_myaccount" */ '../views/users/Edit.vue'),
      },
      {
        path: '/my-company',
        name: routeNames.Companies.MY_COMPANY,
        beforeEnter: isAuthorizedToInstallerPages,
        component: () => import(/* webpackChunkName: "companies_mycompany" */ '../views/companies/MyCompany.vue'),
      },
      {
        path: '/management/installer',
        name: routeNames.App.INSTALLER,
        beforeEnter: isAuthorizedToInstallerPages,
        component: () => import(/* webpackChunkName: "companies_mycompany" */ '../views/management/AppInstaller.vue'),
      },
      {
        path: 'employees',
        name: routeNames.Employees.INDEX,
        component: () => import(/* webpackChunkName: "employees" */ '../views/employees/MyEmployees.vue'),
      },
      {
        path: 'employees/:employeeId',
        name: routeNames.Employees.UPDATE,
        props: true,
        component: () => import(/* webpackChunkName: "employees_update" */ '../views/employees/UpdateEmployee.vue'),
      },
      {
        path: 'employees/new',
        name: routeNames.Employees.NEW,
        component: () => import(/* webpackChunkName: "employees_new" */ '../views/employees/CreateEmployee.vue'),
      },
      {
        path: 'organizations',
        name: routeNames.Organizations.INDEX,
        component: () => import(/* webpackChunkName: "organizations" */ '../views/organizations/Organizations.vue'),
      },
      {
        path: 'organizations/new',
        name: routeNames.Organizations.NEW,
        component: () => import(/* webpackChunkName: "organizations_new" */ '../views/organizations/CreateOrganization.vue'),
      },
    ],
  },
  {
    path: '*',
    name: routeNames.Common.NOT_FOUND,
    component: () => import(/* webpackChunkName: "not_found" */ '../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to: any, from: any, next: any) => {
  const storedVersion = localStorage.getItem('appVersion');

  if (process.env.VUE_APP_VERSION === storedVersion) {
    next();
  } else {
    console.info('New Qontrol version detected');
    localStorage.clear();
    sessionStorage.clear();
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
    localStorage.setItem('appVersion', process.env.VUE_APP_VERSION);
  }
});

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = await auth.authenticate();
  if (unguardedRoutes.includes(to.name as string)) {
    if (isLoggedIn) {
      next({ name: routeNames.Dashboard.HOME });

      notifications.add({
        title: i18n.t('validations.already_logged_in') as string,
        type: 'error',
      });

      return;
    }
  } else if (!isLoggedIn) {
    await auth.logout();
    next({
      name: routeNames.Auth.SIGN_IN,
      query: {
        redirect: to.fullPath,
      },
    });
    return;
  }

  next();
});

export default router;
