import { MessageContext } from 'vue-i18n';

export default {
  common: {
    save_cta: 'Save',
    save_logo: 'Save logo',
    cancel_cta: 'Cancel',
    page_not_found: 'Page not found',
    changes_saved: 'Changes saved',
    error: 'Error',
    error_message: 'An error occurred. Please try again later or contact support.',
    settings: 'Settings',
    search: 'Search',
    try_again: 'Please try again',
    step: 'Step',
    back: 'Back',
    next: 'Next',
    redirect_message: 'You will be redirected to {page} in {seconds} seconds.',
    this_link: 'this link',
  },
  validations: {
    empty_field: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} cannot
    be empty`,
    minimum_characters: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} must
    be at least ${ctx.values[0]} characters long`,
    maximum_characters: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} must
    be at most ${ctx.values[0]} characters long`,
    invalid_characters: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} may
    only contain 0-9, a-z, A-Z, and the following symbols: @ # $ % ^ & * - _ ! + = [ ] { }
    | \\ : ‘ , . ? / \` ~ " ( ) ;`,
    character_combinations: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)}
    requires three of the following: Lowercase characters, Uppercase characters, Numbers, Symbols`,
    same_as: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} must be the same
    as ${ctx.values[0]}`,
    not_checked: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} must be checked`,
    invalid_email: 'The entered email address is invalid',
    invalid_postal_code: 'The entered postal code is invalid',
    invalid_coc: 'The entered COC number is invalid',
    invalid_vat: 'The entered VAT code is invalid',
    invalid_phone_number: 'The entered phone number is invalid',
    invalid_iban: 'The entered IBAN number is invalid',
    invalid_token: 'Your verification link or token is invalid. Please try again',
    incorrect_email_or_password: 'Incorrect email address or password',
    already_logged_in: 'User is already logged in',
    email_not_found: 'Invalid email address',
    email_already_in_use: 'This email address is already in use',
    field_is_not_valid_peak_number: 'Enter the power of the installation with a comma or a period.',
    image_too_large: 'The image is too large. The maximum file size is 1 MB.',
    wrong_file_format: 'The file format is not supported. Please upload a JPG, JPEG or PNG image.',
    invalid_street_number: 'The entered street number is invalid.',
  },
  auth: {
    login_cta: 'Log in',
    log_out_cta: 'Log out',
    no_account_cta: 'I do not have an account yet',
    register_cta: 'Sign up',
    send_reset_cta: 'Send reset link',
    confirm_cta: 'Confirm',
    email_label: 'Email address',
    password_label: 'Password',
    new_password_label: 'New password',
    new_password_confirm_label: 'Confirm new password',
    back_to_login: 'Back to log in',
    forgot_password: 'Forgot Password',
    reset_password: 'Reset your password',
    password_reset: 'Password reset requested',
    password_reset_message: 'A reset link has been sent to: {email}',
    password_reset_token_invalid: 'The password setup/reset link is invalid.',
    password_reset_token_expired: 'The password setup/reset link has expired.',
    create_account: 'Create an account',
    account_created: 'Your account has been created!',
    account_created_message: 'A verification mail has been sent to your email address.',
    choose_password: 'Choose a new password',
    password_set: 'Your password has been set!',
    accept_terms_label: 'I accept the {0}',
    terms_and_conditions: 'terms and conditions',
    login_error: 'Error while logging in',
    login_page: 'the login page',
    redirect_message: 'You will be redirected to the login page in 5 seconds.',
  },
  onboarding: {
    missing_data: 'On this page you will find your personal dashboard. As you can see it is ' +
      'currently empty. Before we can collect your data, we first need some information from you.',
    setup_account: "Let's start with an account.",
    setup_account_2: 'We would like to address you by your first name and would like to know ' +
      'how we can reach you. Filling in your details takes only a minute.',
    setup_account_button: 'Fill in your Account details',
    setup_company: 'How nice that we can address you by your first name. The foundation of ' +
      'your profile has been laid. You are probably here to gain insight into your ' +
      'installations. Please fill in your company profile, so that we can retrieve your data.',
    setup_company_button: 'Fill in the company details',
    setup_installer_profile: 'To draw even more attention to your plant, you have the ' +
      'possibility to determine what company information will be shown to the customer in the ' +
      'Bliq-app. Fill in the company page and publish your data.',
    setup_installer_profile_button: 'Fill in the company page',
    log_out: 'Thank you for registering your business with Bliq! To fully activate your ' +
      'account, we ask you to log out and log in again. From that moment on it is possible to ' +
      'create and manage new installations. After logging in again you can complete the ' +
      'registration of your company.',
  },
  dashboard: {
    dashboard: 'Dashboard',
    dashboard_page: 'the dashboard',
    hi: 'Hi',
    welcome_header: 'Welcome to Qontrol!',
    welcome_message: 'Welcome to your dashboard.',
    no_notifications: 'There are currently no notifications.',
    logout: 'Logout',
    my_account: 'My Account',
    my_company: 'My Company',
    my_company_page: 'My Company page',
    status_online: 'Installations online',
    status_offline: 'Installations offline',
    status_degraded: 'Communication problems',
    status_incomplete: 'Incomplete',
    status_notTested: 'Installations not tested',
    status_failed: 'Installations failed test',
    my_users: 'My users',
    third_parties: 'Third parties',
  },
  widgets: {
    communication_status: 'Communication status',
    plants: 'plants',
  },
  availability: {
    all: 'All',
    online: 'Online',
    incomplete: 'Incomplete',
    degraded: 'Degraded',
    offline: 'Offline',
    unknown: 'Unknown',
  },
  setup: {
    register_customer: 'Sign up customer',
    register_device: 'Sign up Bliq-vanger',
    register_installation: 'Sign up installation',
    new_installation: 'New installation',
    address: {
      add_address: 'Add address',
      country: 'Country',
      country_nl: 'The Netherlands',
      country_be: 'Belgium',
      postal_code: 'Postal code',
      house_number: 'Street number',
      house_number_extention: 'Ext.',
      street: 'Street name',
      city: 'City',
      invalid_postal_code: 'The entered postal code is invalid',
    },
    bliqvanger: {
      register_bliqvanger: 'Register Bliq-vanger',
      add_bliqvanger: 'Add Bliq-vanger',
      peak_power: 'Peak power (kWp)',
      scan: 'Scan QR code',
      cancel_scan: 'Cancel',
      add_manually: 'Add manually',
      serial_number: 'Serial number Bliq-vanger',
      add: 'Add',
      added_bliqvangers: 'Added Bliq-vangers:',
      added_bliqvanger: 'Added Bliq-vanger:',
      added_bliqvanger_success: 'The Bliq-vanger has been added to the installation',
      device_serial_number_invalid: 'Device serial number entered is invalid.',
      device_serial_number_present: 'Device serial number entered is already present in the list.',
      'collector_error_DEVICE-0001': 'Bliq-vanger with the provided serial number could ' +
        'not be found.',
      'collector_error_DEVICE-0002': 'Bliq-vanger with the provided serial number already exists.',
      'collector_error_DEVICE-INSTALL-0002': 'Installation could not be found in IGEN portal.',
    },
    customer: {
      register_customer: 'Register customer',
      first_name: 'First name',
      last_name: 'Last name',
      phone_number: 'Phone number',
    },
    connection: {
      installation_attributes: 'Add installation attributes',
      installer_id: 'Installer ID',
    },
    overview: {
      overview: 'Overview',
      success: 'Success!',
      new_device_is_paired: 'Your Bliq-vanger will now be paired.',
      new_device_additional_message: 'It might take a few seconds. Please refresh the page if ' +
        'you do not see your installation in the list.',
      name: 'Name',
      address: 'Address',
      email_address: 'E-mail',
      phone_number: 'Phone number',
      bliqvanger: 'Bliq-vanger',
      peak: 'Peak Power',
    },
    installations: {
      must_start_from_first_step: 'You must start filling in the form from the first step.',
    },
  },
  installations: {
    installations: 'Installations',
    installation: 'Installation',
    new_installation: 'New installation',
    customer: 'Customer',
    address: 'Address',
    status: 'Status',
    production_capacity: 'Capacity (kWp)',
    device: 'Device',
    devices: 'Devices',
    device_id: 'Device ID:',
    error_loading_power: 'Error while loading data',
    status_error: 'Could not retrieve the installation status',
    installations_error: 'Could not retrieve the installations',
    device_no_data: 'No data',
    device_no_recent_measurements: 'No recent measurement',
    device_status_error: 'Could not retrieve devices status',
    monitoring_view: 'Monitoring',
    schematic_view: 'Schematic view',
    list_view: 'List view',
    production_consumption: 'Production / consumption (W)',
    reseller_id: 'Reseller ID',
    installerName: 'Reseller name',
    smartMeter: 'Smart meter',
    battery: 'Battery',
    registration: 'Registration',
    installationDate: 'Installation date',
    force_sync_devices: 'Force sync devices',
    force_sync_devices_in_progress: 'No connected devices found. Please check the configuration.',
    not_tested: 'Not tested installations',
    failed: 'Failed installations failed',
    no_data: 'No data',
    not_tested_no_data: 'There is no not tested installations',
    failed_no_data: 'There is no failed installations',
    test: 'Test',
    logs: 'Logs',
    device_logs: {
      device_logs_title: 'Executed commands',
      command_started: 'Command Started',
      command_name: 'Command',
      command_value: 'Power',
      command_status: 'Status',
      command_failure_reason: 'Failure Reason',
      command_finished: 'Command Finished',
      display: 'Display',
    },
  },
  measurements: {
    power: 'W',
    pv_power: 'W',
    battery_power: 'W',
    prices: '€',
    export: 'kWh',
    battery_soc: '%',
  },
  devices: {
    usage: 'Usage',
    power: 'power',
    pv_power: 'power',
    battery_power: 'power',
    prices: 'Day ahead prices',
    battery_soc: 'state of charge',
    export: 'production',
    buying_selling: 'Buying and selling',
    devices: 'Devices',
    bliq: 'Bliq',
    smart_meter: 'Smart meter',
    ev_charger: 'EV Charger',
    airco: 'Airco',
    heat_pump: 'Heat Pump',
    house: 'Consumption',
    grid: 'Smart meter',
    battery: 'Battery',
    solar_panel: 'Solar panel',
    solar_panels: 'Solar panels',
    gas_meter: 'Gas meter',
    collector: 'Bliq-vanger',
    inverter: 'Inverter',
    type_rf_sub_device: 'RF Dongle',
    kwh_meter: 'Bliq-kWh-meter',
    meter: 'Bliq-kWh-meter',
    cta_edit: 'Edit',
    cta_save: 'Save',
    add_label: 'Add new label',
    label: 'Label',
    change_config: 'Change the configuration of the device',
    basic_info: 'General information',
    attention_needed: 'Attention needed!',
    kwh_meter_config_needed: 'We see that you have connected a kWh meter. In order to properly ' +
      'display the information in the app and Qontrol, it is necessary that you make a choice ' +
      'how you want to use it or which device you have connected it to.',
    inverter_config_needed: 'It appears that this inverter is part of a system. Please select ' +
      'the situation that applies:',
    connected_device: 'Connected device',
    select_device: 'Select the device',
    change_error: 'Unfortunately, the data could not be saved. Try again. If it doesn\'t work, ' +
      'please contact us at support@bliq.energy',
    subtype_success: 'Changed the subtype of the device',
    label_success: 'Changed the label of the device',
    device_error: 'Could not get the device details',
    device_logs_error: 'Could not get the device logs',
    inverter_only: 'Solar panels only',
    battery_only: 'Battery only',
    battery_and_inverter: 'Solar panels with battery',
    inverter_brand: 'Inverter brand',
    battery_brand: 'Battery brand',
    inverter_type: 'Inverter type',
    battery_type: 'Battery type',
    number_of_batteries: 'Number of batteries',
    select_type: 'Select type',
    select_brand: 'Select brand',
    batteries_amount: 'Amount of battery modules',
    not_now_button: 'Not now',
    ok_now_button: 'Configure next device',
    you_have_unconfigured_devices: 'You have {number} unconfigured device(s).',
    configure_your_devices: 'Configure all your devices to see their details.',
    invalid_manager_configuration: 'Wrong manager configuration. All devices should have the same manager.',
    self_test: {
      self_test_explanation: 'For more information regarding the self test and not succeeding test, kindly vist our knowledge base by clicking {self-test-link}' +
        'The maximum duration of a self test is 20 minutes. Longer running tests can be aborted by closing the page and the test will have failed.',
      self_test_result_title: 'Self test result {date}',
      run_self_test: 'Run self test',
      abort_self_test: 'Abort self test',
      update_frequency: 'Bliq-vanger ({serialNumber}) update frequency set to 1 min',
      set_inverter_standard_settings: 'Set the standard settings for this inverter',
      check_smart_meter_installed: 'Checking installed smart meter',
      send_battery_power_command: 'Send command for charging/discharging battery',
      queued_for_testing: 'Queued for testing',
      testing: 'Test started at {startedAt} ...',
      test_failed: 'Test failed at {time}. Duration: {duration}',
      test_not_ready: 'Test is not ready to start yet',
      test_succeeded: 'Successfully tested at {time}. Duration: {duration}',
      test_unknown: 'The test result is unknown',
      test_summary_result: 'Test started at {startedAt} by {executedBy} and finished at {finishedAt}. Duration: {duration}.',
      test_summary_status_passed: 'Status: Passed.',
      test_summary_status_failed: 'Status: Failed.',
      test_summary_status_passed_manually_adjusted: 'Status: Passed (Manually adjusted).',
      test_summary_status_failed_manually_adjusted: 'Status: Failed (Manually adjusted).',
      cant_run_test_tite: 'Can\'t run self test now',
      cant_run_test_description: 'Please try again later',
      startedAt: 'Started at',
      status: 'Status',
      duration: 'Duration',
      loading_data: 'Loading data...',
      change_status: 'Change status',
      executed_by: 'By',
    },
  },
  calendar: {
    sameDay: 'Today',
    lastDay: 'Yesterday',
    nextDay: 'Tomorrow',
    lastWeek: 'Last',
    nextWeek: 'Next',
    day: 'Day',
    isoWeek: 'Week',
    month: 'Month',
    year: 'Year',
  },
  chart: {
    lang: {
      loading: 'Loading...',
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      weekdays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      thousandsSep: ' ',
      decimalPoint: '.',
      resetZoom: 'Reset zoom',
    },
  },
  customers: {
    customer: 'Customer',
    customers: 'Customers',
    new_customer: 'New customer',
    no_data: 'There are no customers yet.',
    no_data2: 'Click the button at the top right to add a new customer.',
    no_search_results: 'No search results found.',
    installation_date: 'Installation date',
    customers_error: 'Could not retrieve the customers',
    name_or_postcode: 'Name or postal code',
    search_by_name_or_postcode: 'Search by name or postal code',
    customer_not_found: 'No correct results? Then your customer has not yet registered with Bliq.',
    no_results: 'No results?',
    customer_not_registered: 'Then your customer has not yet registered with Bliq. Choose ' +
      'customer registration.',
    customer_no_data: 'No existing data? Your customer has not yet registered with Bliq. Choose',
    start_installation: 'Start installation',
    details: {
      customer_error: 'Cannot retrieve customer details',
      customer_data: 'Customer details',
      name: 'Name',
      customer_id: 'Customer ID',
      address_data: 'Address details',
      address: 'Address',
      billing_address: 'Billing address',
      go_to_installation: 'Go to installation',
      add_collector: 'Start installation',
      add_BV: 'Start',
      email: 'Email',
      phone_number: 'Phone number',
      installation_id: 'Installation ID',
      installer_name: 'Installer',
      installation_created_date: 'Created date',
      installation_created_by: 'Created by',
      installation_updated_date: 'Updated date',
      installation_updated_by: 'Updated by',
      registration_process_status: 'Onboarding',
      energy_company: 'Energy company',
      energy_contract: 'Energy contract',
      subscription_product: 'Product',
      subscription_start_date: 'Subscription start date',
      appStatus: 'App status',
      appConnected: 'App connected',
      appConnectedInstallation: 'App connected to installation',
      appNotConnected: 'No app connected to this email address',
      watcherFunction: 'Watcher function',
    },
  },
  users: {
    users: 'Users',
    account: 'Account',
    my_account: 'My account',
    general_information: 'General information',
    first_name: 'First name',
    last_name: 'Last name',
    email_address: 'Email address',
    phone_number: 'Phone number',
    language: 'Language',
    language_en: 'English',
    language_nl: 'Dutch',
    general_information_saved: 'Your general information has been saved succesfully.',
    change_password: 'Change password',
    password: 'Password',
    current_password: 'Current password',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    password_saved: 'Your password has been saved successfully.',
    rights: 'User rights',
    give_the_user_admin_rights: 'Give the user admin rights',
    block_user: 'Block user',
    rights_saved: 'The user rights have been saved successfully.',
    new_user: 'New user',
    no_data: 'There are no users yet.',
    no_data_sub_text: 'Click the button at the top right to add users.',
    already_exists: 'User already exists',
  },
  companies: {
    companies: 'Companies',
    company_profile: 'Company profile',
    country: 'Country',
    company_info: 'Company info',
    coc_number: 'Commerce number',
    company_name: 'Company name',
    address: 'Address',
    postal_code: 'Postal code',
    house_number: 'Street number',
    house_number_extention: 'Ext.',
    street: 'Street name',
    town: 'City',
    financials: 'Financial information',
    vat_number: 'VAT number',
    iban_number: 'IBAN number',
    country_nl: 'The Netherlands',
    country_be: 'Belgium',
    new_company: 'New company',
    data_policy: 'data policy',
    company_saved: 'The company profile has been saved successfully.',
    company_logo_saved: 'The company logo has been saved successfully.',
    company_published: 'The company page is published',
    company_unpublished: 'The company page is unpublished',
    postal_code_not_found: 'The entered postal code was not found, please check your input and ' +
      'try again. If the info is correct, then please add the street name and city manually.',
    last_update: 'Last update',
    published: 'Published',
    publish: 'Publish',
    hidden: 'Hidden',
    visible: 'Visible',
    visibility: 'Visibility',
    my_company_page: 'My company page',
    see_the_preview: 'See the preview',
    publish_your_company_page: 'Publish your company page',
    unpublish_your_company_page: 'Unpublish',
    intro: 'On this page you can determine which company information will be shown in the ' +
      'Bliq-app.',
    heading_2: 'How does it work?',
    text: 'You decide what type of information your customer will see on the My installer page ' +
      'of the app.\nA company name, the company phone, and email are mandatory fields.',
    info_alert: 'The information managed on this page is directly visible to the Bliq app user. ' +
      'Therefore, please note that the contact details entered here are for your customers.',
    call_the_mechanic: 'Call the mechanic',
    visit_us: 'Visit us',
    email_us: 'Email us',
    call_us: 'Call us',
    we_are_here: 'We are here',
    no_logo_text: 'No logo uploaded yet',
    logo_upload: 'Upload logo',
    change_logo: 'Change logo',
    company_already_exists: 'It\'s not possible to create a new account for your company, ' +
      'because an account already exists. Please check with your colleagues.',
    need_help: 'Need help? Contact us via support@bliq.energy.',
  },
  appInstaller: {
    info: 'Company Details',
    preview_company_page: 'Preview of your page',
    visit: 'Visit adress',
    companyName: 'Company Name',
    website: 'Website',
    email: 'E-mail Address',
    phone: 'Phone',
    street: 'Street',
    house_number: 'Housenumber',
    additions: 'Addition',
    zipcode: 'Zipcode',
    city: 'City',
  },
  third_parties: {
    already_exists: 'Third party already exists',
    organization_name: 'Organization name',
    new_third_party: 'New third party',
    no_data: 'There are no third parties yet.',
    no_data_sub_text: 'Click the button at the top right to add third parties.',
  },
};
