import {
  Action, Module, Mutation, VuexModule
} from 'vuex-class-modules';
import { identity as identityApi } from '@/api';
import LoginResponse from '@/api/dtos/LoginResponse';
import ActionTypes from '@/models/ActionTypes';
import ValidationError from '@/errors/Validation';
import UnauthorizedError from '@/errors/Unauthorized';
import NotFoundError from '@/errors/NotFound';
import ApiError from '@/errors/Api';
import constants from '@/constants';
import UserV2 from '@/models/UserV2';
import store from '../index';

@Module
class Auth extends VuexModule {
  private static LOCAL_STORAGE_KEY = 'auth';

  private static _refreshTokenTimeoutId?: number;

  userRole = '';

  accessToken = '';

  roleAccessToken = '';

  userEmail = '';

  userInstallerId?: string = undefined;

  get role(): string {
    return this.userRole;
  }

  get email(): string {
    return this.userEmail;
  }

  get hasAdminRole(): boolean {
    return this.userRole.toLocaleLowerCase() === constants.USER_ROLES.BLIQ_ADMIN;
  }

  get hasInstallerRole(): boolean {
    return this.userRole.toLocaleLowerCase() === constants.USER_ROLES.INSTALLER_MAIN;
  }

  get hasMechanicRole(): boolean {
    return this.userRole.toLocaleLowerCase() === constants.USER_ROLES.MECHANIC;
  }

  get hasInstallerEmployeeRole(): boolean {
    return this.userRole.toLocaleLowerCase() === constants.USER_ROLES.INSTALLER_EMPLOYEE;
  }

  get hasInstallerId(): boolean {
    return !!this.userInstallerId;
  }

  get installerId(): string | null {
    return this.userInstallerId || null;
  }

  get getAccessToken(): string {
    return this.accessToken;
  }

  get getRoleAccessToken(): string {
    return this.roleAccessToken;
  }

  @Action
  async getLoggedInUser(ignoreStorage = false): Promise<UserV2 | undefined> {
    if (this.roleAccessToken === '' || this.accessToken === '') {
      try {
        const result = await identityApi.refreshToken();
        this.saveAccessTokenId(result.id_token);
        this.saveRoleAccessToken(result.access_token);
        this.scheduleTokenRefresh(result.expires_in);
      } catch (error) {
        await this.logout();
        return undefined;
      }
    }
    this.saveRole(this.getRoleFromToken(this.roleAccessToken));
    this.saveInstallerId(this.getInstallerIdFromToken(this.roleAccessToken));
    try {
      let loggedInUser: UserV2;
      if (!ignoreStorage) {
        const loggedInUserString = localStorage.getItem('loggedInUser');
        if (loggedInUserString) {
          loggedInUser = JSON.parse(loggedInUserString as string);
          return loggedInUser;
        }
      }

      loggedInUser = await identityApi.getCurrentUserDetails();
      localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
      return loggedInUser;
    } catch (error) {
      this.logout();
      return undefined;
    }
  }

  @Action
  async login(payload: {email: string, password: string}): Promise<void> {
    this.saveEmail(payload.email);
    try {
      const result = await identityApi.login(payload.email, payload.password);
      this.saveAccessTokenId(result.id_token);
      this.saveRoleAccessToken(result.access_token);
      await this.refreshToken();
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        // Server doesn't return error message, so give the caller one.
        throw new ValidationError('validations.incorrect_email_or_password');
      }

      throw e;
    }

    // TODO: Store token instead of email.
    localStorage.setItem(Auth.LOCAL_STORAGE_KEY, payload.email);
  }

  @Action
  async loginAsCustomer(payload: { customerId: string }): Promise<void> {
    const loginResponse = await identityApi.loginAsCustomer(payload.customerId);
    this.openPreviewFunction(loginResponse.access_token);
  }

  // method for refresh token when time for token is expired
  @Action
  async refreshToken(): Promise<LoginResponse | null> {
    try {
      const result = await identityApi.refreshToken();
      this.saveAccessTokenId(result.id_token);
      this.saveRoleAccessToken(result.access_token);
      this.scheduleTokenRefresh(result.expires_in);

      return result;
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        // Server doesn't return error message, so give the caller one.
        throw new ValidationError('validations.problem_with_refresh_token');
      }
      await this.logout();
      throw error;
    }
  }

  @Action
  async logout(): Promise<void> {
    this.clearRefreshTokenTimeout();
    // TODO: Use http-only cookie instead of localStorage (depends on the backend)
    localStorage.removeItem(Auth.LOCAL_STORAGE_KEY);
    localStorage.removeItem('loggedInUser');
    sessionStorage.clear();
    this.clean();
    try {
      await identityApi.logout();
    } catch (e) {
      console.warn('logout', e);
    }
  }

  @Action
  async sendPasswordResetLink(email: string): Promise<void> {
    await identityApi.forgotPassword(email);
  }

  @Action
  async setPassword(payload: {
    token: string,
    email: string,
    action: ActionTypes,
    createdAt: string,
    password: string,
    accepted?: {
      userTermsAndConditionsUrl: string
    },
  }): Promise<void> {
    try {
      switch (payload.action) {
        case ActionTypes.NEW_ACCOUNT:
          await identityApi.confirmRegistration({
            token: payload.token,
            email: payload.email,
            accepted: {
              userTermsAndConditionsUrl: payload.accepted?.userTermsAndConditionsUrl,
            },
            createdAt: +payload.createdAt,
            password: payload.password,
          });
          break;
        case ActionTypes.RESET_PASSWORD:
          await identityApi.setPassword({
            token: payload.token,
            email: payload.email,
            createdAt: +payload.createdAt,
            password: payload.password,
          });
          break;
        default:
          throw new ValidationError('validations.invalid_token');
      }
    } catch (e) {
      if (e instanceof NotFoundError || (e instanceof ApiError &&
        (e as ApiError).code === 400)) {
        throw new ValidationError('validations.invalid_token');
      }

      throw e;
    }
  }

  @Action
  async authenticate(): Promise<boolean> {
    const loggedInUser = await this.getLoggedInUser();

    return !!loggedInUser;
  }

  @Action
  async register(payload: {email: string}): Promise<void> {
    try {
      await identityApi.beginRegistration(payload.email);
    } catch (e) {
      if (
        e instanceof ApiError &&
        (e as ApiError).code === 400 &&
        (e as ApiError).response?.userExists
      ) {
        throw new ValidationError('validations.email_already_in_use');
      } else {
        throw e;
      }
    }
  }

  @Mutation
  saveRole(role: string): void {
    this.userRole = role;
  }

  @Mutation
  saveAccessTokenId(token: string): void {
    this.accessToken = token;
  }

  @Mutation
  saveRoleAccessToken(token: string): void {
    this.roleAccessToken = token;
  }

  @Mutation
  saveEmail(email: string): void {
    this.userEmail = email;
  }

  @Mutation
  cleanEmail(): void {
    this.userEmail = '';
  }

  @Mutation
  saveInstallerId(installerId?: string): void {
    this.userInstallerId = installerId;
  }

  @Mutation
  clean(): void {
    this.userInstallerId = '';
    this.userEmail = '';
    this.accessToken = '';
    this.roleAccessToken = '';
  }

  private getRoleFromToken(accessToken: string): string {
    const token = accessToken.split('.')[1];
    const decodedToken = JSON.parse(atob(token));
    const role = decodedToken.extension_Role;
    return role;
  }

  private getInstallerIdFromToken(accessToken: string): string | undefined {
    const token = accessToken.split('.')[1];
    const decodedToken = JSON.parse(atob(token));
    const installerId = decodedToken.extension_InstallerId;
    return installerId;
  }

  private clearRefreshTokenTimeout(): void {
    if (Auth._refreshTokenTimeoutId) {
      clearTimeout(Auth._refreshTokenTimeoutId);
      Auth._refreshTokenTimeoutId = undefined;
    }
  }

  private scheduleTokenRefresh(expiresIn: number): void {
    this.clearRefreshTokenTimeout();
    const expirationDate = new Date();
    const expireTimeout = expirationDate.getMilliseconds() + 900 * expiresIn;
    // call refresh token method a little earlier before expired time (-10%)
    Auth._refreshTokenTimeoutId = setTimeout(this.refreshToken, expireTimeout);
  }

  private openPreviewFunction(token: string): void {
    const accessTokenUri = `accessToken=${encodeURIComponent(token)}`;
    const url = `${process.env.VUE_APP_BROWSER_PREVIEW_URL}/ViewAsCustomer.html?${accessTokenUri}`;
    window.open(url, '_blank');
  }
}

export default new Auth({
  store,
  name: 'auth',
});
